import React from 'react'
import { Link } from "gatsby"

class ResponsabileReparto extends React.Component {

  render() { 
    const responsabile = this.props.data;
   
    return (
      <div className="webcontent">
        <h2 className="webcontent__title">Responsabile</h2>
          <img loading="lazy" width="160" src={responsabile.foto.file.url} alt={responsabile.nome + responsabile.cognome} />
          <h3 class="mt-3">
            {!!responsabile.schedaStaff ? (
              <Link to={`/staff/${responsabile.schedaStaff.friendlyUrl}`}>{responsabile.nome} {responsabile.cognome}</Link>  
            ):(
              <>{responsabile.nome} {responsabile.cognome}</>
            )}
          </h3>
      </div>
    )
  }

}



export default ResponsabileReparto;