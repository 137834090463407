import React from 'react'
import Helmet from 'react-helmet'
import PhotoGallery from '../components/photogallery'
import Faq from '../components/faq'
import ResponsabileReparto from '../components/responsabile-reparto'
import Contatti from '../components/contatti'
import Breadcrumbs from '../components/breadcrumbs'
import Placeholder from '../components/placeholder'
import Layout from "../components/layout"
import DocumentiUtili from '../components/documenti-utili'
import { graphql } from "gatsby"
import Team from '../components/team'

class ServizioTemplate extends React.Component {

  render() { 
    const data = this.props.data.contentfulSchedaServizioDiagnostico;
    const breadcrumb = [
      {"title":"Reparti","link":"/reparti/"},
      {"title":  data.titolo}
    ];
    

    //let prenotabile = (data.prenotabile == null || data.prenotabile)?true:false;
    const responsabile =  data.responsabile;
    return (
      <Layout  location={this.props.location}>
        <div className="container">
          <Helmet title={data.titolo}/>
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <div className="row">
            <div className="col-md-8">
              <div className="servizi">
                <h1 className="servizi__title">{data.titolo}</h1>
                {!!data.descrizione &&
                  <div className="servizi__bodytext" 
                      dangerouslySetInnerHTML={{ __html: data.descrizione.childMarkdownRemark.html }} >
                  </div>
                }
                {!!data.approfondimento &&
                  <h2>Approfondimenti</h2>
                }  
                <div className="row flex-wrap">
                  {!!data.approfondimento && data.approfondimento.map((item,index) => {
                    let dataserv = { 
                      "title": item.titolo,
                      "link": "/servizi/"+item.friendlyUrl
                      };
                    return (
                      <div key={index} className="col-12">
                        <Placeholder data={dataserv}/>
                      </div>
                    )
                  })

                  }
                </div>
                
                {!!data.imageGallery &&
                  <div className="magnific-popup">
                    <h2 className="servizi__subject">Fotogallery</h2>
                    <PhotoGallery data={data.imageGallery}/>
                  </div>
                }
                {!!data.faq &&
                  <div className="faq">
                    <h2 className="servizi__subject">FAQ</h2>
                    <Faq data={data.faq}/>
                  </div>
                }
              </div>
            </div>
            <div className="col-md-4">
             
              {!!responsabile &&
                <ResponsabileReparto data={responsabile}/>
              }
               {data.team && 
                <Team data={data} type="d"/>
              }

              <Contatti data={data}/>
              {!!data.documentiUtili &&
                <DocumentiUtili data={data.documentiUtili}/>
              }
            </div>
          </div>
        </div>
      </Layout>
    )
  }

}

export default ServizioTemplate;

export const pageQuery = graphql`query ServizioDiagnosticaDetailQuery($contentful_id: String!) {
  contentfulSchedaServizioDiagnostico(contentful_id: {eq: $contentful_id}) {
    id
    contentful_id
    titolo
    descrizione {
      childMarkdownRemark {
        html
      }
    }
    indirizzo
    mail
    #contatti {
    #  childMarkdownRemark {
    #    html
    #  }
    #}
    telefono
    team {
      cognome
      nome
    }
    prenotabile
    approfondimento {
      id
      friendlyUrl
      titolo
    }
    documentiUtili {
      title 
      file {
        url
      }
    }
    faq {
      domanda
      risposta {
        childMarkdownRemark {
          html
        }
      }
    }
    team {
      nome 
      cognome
      friendlyUrl
    }
    responsabile {
      nome
      cognome
      schedaStaff {
        friendlyUrl
      }
      foto {
        file {
          url
        }
      }
    }
    imageGallery {
      ...PhotoGalleryFragment
    }
  }
}
`;
